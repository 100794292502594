export const httpTransactionsConstants = () => 'transaction/constants';
export const httpTransactionItem = (id: number) => `transaction/${id}`;
export const httpTransaction = () => `transaction`;
export const httpTransactionsReferences = (id: number) => `transaction/${id}/references`;
export const httpTransactionsSplitBucket = (id: number) => `transaction/${id}/split`;
export const httpTransactionsAllocDef = (id: number) => `transaction/${id}/allocdefault`;
export const httpTransactionsAllocNonAchPmnt = (id: number) => `transaction/${id}/allocnonachpmnt`;
export const httpTransactionsACHSearch = () => 'transaction/achsearch';
export const httpTransactionsReleaseBulk = () => 'transaction/release';
export const httpCommissionActivateCandidates = () => 'transaction/commissionactivatecandidates';
export const receivablesAch = (transDate: string, days: number) => `transaction/receivablesach/${transDate}/${days}`;

export const httpBETransactionsEvents = (id: number) => `be/transaction/${id}/events`;
export const httpTransactionChangelog = (id: number) => `transaction/${id}/changelog`;

export const httpCreateCommissionInvoice = () => 'accounting/invoice/commission';
export const httpCommissionInvoiceSummary = () => 'accounting/invoice/commission/summary';

export const httpTransactionList = () => `transaction/list`;
export const httpTransactionsBulk = () => 'transaction/bulk';
export const httpQBManualTransactions = (mcaid: number) => `mca/${mcaid}/qbmanual_transactions`;
export const httpMcaActivateDeposit = (mcaid: number, type: string, transid: number, status: number, venue: string) =>
  `mca/${mcaid}/activatedeposit/${type}/${transid}/${status}/${venue}`;
export const httpMcaActivateContractFee = (mcaid: number, transid: number) => `mca/${mcaid}/activatecontractfee/${transid}`;
export const httpMcaSyndTrans = (id: number) => `mca/${id}/trans/investors`;
export const httpMcaComUsersTrans = (id: number) => `mca/${id}/trans/comusers`;
export const httpMcaFailedUnproccessedTrans = (id: number) => `mca/${id}/failedunproccessedtrans`;
export const httpMcaFinalizeCommissions = (id: number) => `mca/${id}/finalizecommissions`;
export const httpMcaGenerateCommissions = (id: number) => `mca/${id}/generatecommissions`;
export const httpMcaGenerateMCACommission = (id: number) => `mca/${id}/generatemcacommission`;
export const httpMcaTransContractFee = (mcaId: number, transId: number) => `mca/${mcaId}/contractfee/${transId}`;
export const httpMcaTransHold = (id: number) => `mca/${id}/transactions/hold`;
export const httpMcaUnwindCommissions = (id: number) => `mca/${id}/unwindcommissions`;
export const httpRestructuring = (mcaId: number) => `mca/${mcaId}/restructuring`;
export const httpActivateFeeToInvestor = () => 'mca/activatefeetoinvestor';
export const httpMcaGeneratePayments = (id: number) => `mca/${id}/transaction/generatepayments`;
export const httpSettlementRequestParameters = (id: number) => `mca/${id}/settlementrequest`;
export const httpSettlementAttributes = (id: number) => `mca/${id}/settlement_attributes`;
export const httpSettlementDurationInfo = () => `mca/settlement_payments`;
export const httpSettlementRequest = (id: number) => `mca/${id}/settlement`;

export const httpMcaNextPayment = (id: number) => `mca/${id}/transaction/nextpayment`;
export const httpMcaRequestPayments = (id: number) => `mca/${id}/requestpayments`;

export const httpTransactionAggregation = () => 'transaction/aggregation';
export const httpTransactionsMerge = () => `transaction/mergebuckets`;
export const httpTransactionHold = (id: number) => `transaction/${id}/hold`;
export const httpTransactionVoid = (id: number) => `transaction/cc/void/${id}`;
export const httpTransactionTransferAccount = (id: number) => `transaction/${id}/transferes`;

export const httpTransactionAssign = (transId: number) => `transaction/${transId}/assign`;
export const httpTransactionUnassign = (transId: number) => `transaction/${transId}/unassign`;
export const httpTransactionCollectorUnallocated = (userId: number) => `transaction/collectors/${userId}/unallocated`;
export const httpTransactionCollectorAllocate = (transId: number) => `transaction/${transId}/collectors/allocate`;

export const httpQuickBooks = (module: string) => `quickbooks/${module}`;
export const httpTransactionNonACHSearch = () => 'transaction/nonachsearch';

export const httpDefaultFeePayment = (mcaId: number) => `mca/${mcaId}/transactions/fees/default`;
export const httpStackingFeePayment = (mcaId: number) => `mca/${mcaId}/transactions/fees/stacking`;

export const httpDefaultFees = () => `pm/investorsdefaultfeeallocation`;

export const httpActivateBPWithdrawalTransfer = (transId: number) => `transaction/${transId}/activatebpwithdrawaltransfer`;
export const httpActivateWithdrawalTransaction = () => `transaction/withdrawal/activate`;
